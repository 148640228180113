const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  dkGrayColor : '#3b3b3b',
  orangeColor : '#c5b885',
  darkOrangeColor :'#7f7033',
  darkBlueColor : '#1c1f45',
  yellowColor : '#c67f07'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"roboto-condensed",serif',

  primaryColor: colors.yellowColor,
  fontColor: colors.dkGrayColor,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;